<template>
    <v-container class="synchook d-flex justify-center h-screen">
        <h2 v-if="!isCanary">Canary version enabled. Reloading in {{ countdown }} seconds...</h2>
        <h2 v-else>Canary version is enabled. If a browser reload didn't work, try clearing your cache.</h2>
    </v-container>
</template>
<script>
export default {
    mounted() {
        if (!this.isCanary) {
            this.enableCanaryVersion();
        }
    },
    data() {
        return {
            countdown: 3
        }
    },
    computed: {
        isCanary() {
            const re = /__secure-canary=(.*)/;
            const canaryCookieValue = document.cookie.split(";").find((cookie) => cookie.trim().match(re))?.match(re)[1] || 0;
            return canaryCookieValue != 0;
        },
    },
    methods: {
        async enableCanaryVersion() {
            document.cookie = `__secure-canary=1; Domain=brakecode.com; SameSite=None; Secure; Expires=2147483647;`;
            this.$backend.axiosCache.defaults.headers.common["x-environment"] = "canary";
            
            setTimeout(this.$router.go, this.countdown * 1000);
            while (this.countdown > 0) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                this.countdown -= 1;
            }
            
        }
    }
};
</script>